<template>
  <v-container fluid>
    <v-row class="">
      <v-col xl="3" lg="2" md="2" sm="12">
        <BackButton :handler="gotoFacilities"/>
      </v-col>
      <v-col lg="4" md="4" sm="12">
        <div class="d-flex justify-center gap-x-6 align-center">
          <div style="max-width: 16rem;max-height: 10rem">
            <view-image  :image="facilityDetails.image" class="rounded-lg" :width="200" :height="165"  defaultImage="ground" />
          </div>
          <div class="mt-2">
            <h3 class="text-xl m-b-4">
              {{facilityDetails.name }}
            </h3>
<!--            <p v-if="facilityDetails.game_formations.length">Configuration: {{getGameFormationName()}}</p>-->
<!--            <p v-if="facilityDetails.facility_surface">Surface: </p>-->

          </div>

        </div>
      </v-col>

      <v-col lg="3" sm="12" class="mt-sm-3  d-sm-flex">

        <v-btn
            class="ma-2  text_capitalize "
            outlined
            @click="deleteGround(facilityDetails.id)"
            :color="facilityDetails.status_id == 1?'red':''"
            v-if="checkDeletePermission($modules.facility.management.slug)"
        >
          <span class="ml-1">{{ facilityDetails.status_id == 1 ? "Delete" : "Activate" }} </span>
        </v-btn>
        <v-btn
            style="background-color: rgba(17, 42, 70, 0.1) ; color:#112A46 "
            class="ma-2  text_capitalize "
            outlined
            @click="edit(facilityDetails.id)"
            v-if="checkWritePermission($modules.facility.management.slug)"
        > <EditBtnIcon/>
          <span class="ml-1">Edit</span>   </v-btn>
      </v-col>
    </v-row>

    <v-row class="mt-5">
        <v-col cols="12">
          <v-card class="shadow-0">
            <v-card-actions>
              <v-tabs
                  v-model="tab"
                  background-color="transparent"
                  centered
                  icons-and-text
                  slider-color="transparent"
                  active-class="tab_active"
                  class="q-tabs-secondary shadow-0  border-bottom"
              >
                <v-tab href="#details"
                       active-class="active_tab_stroke_icon"
                       class=" border-bottom ">
                  <SvgIcon text="Details">
                    <template v-slot:icon>
                      <PackagesIcon class="svg-stroke-neon"/>
                    </template>
                  </SvgIcon>
                </v-tab>

                <v-tab href="#maintenance"
                       active-class="active_tab_stroke_icon"
                       class=" border-bottom ">
                  <SvgIcon text="Maintenance">
                    <template v-slot:icon>
                      <PackagesIcon class="svg-stroke-neon"/>
                    </template>
                  </SvgIcon>
                </v-tab>
                <v-tab href="#colorPalette"
                       v-if="venueServiceConfiguration.is_golf_enabled == 1"
                       active-class="active_tab_stroke_icon"
                       class=" border-bottom ">
                  <SvgIcon text="Slots">
                    <template v-slot:icon>
                      <PackagesIcon class="svg-stroke-neon"/>
                    </template>
                  </SvgIcon>
                </v-tab>
                <v-tab href="#teeConfiguration"    v-if="checkReadPermission($modules.facility.control.slug)"
                       active-class="active_tab_stroke_icon"
                       class=" border-bottom ">
                  <SvgIcon text="Schedule Control">
                    <template v-slot:icon>
                      <PackagesIcon class="svg-stroke-neon"/>
                    </template>
                  </SvgIcon>
                </v-tab>
              </v-tabs>
            </v-card-actions>


            <v-card-text>
              <v-tabs-items v-model="tab">
                <v-tab-item value="details">
                  <v-row>
                    <v-col md="6">
                      <v-card class="fill-height bordered shadow-0" >
                        <v-card-text class="mb-4">
                          <v-row dense class="border-bottom">
                            <v-col cols="12">
                              <div class="d-flex justify-space-between align-center">

                                <p class="font-bold text-lg black-text">Sales Summary</p>
                                <v-spacer/>
                                <v-btn-toggle
                                    borderless
                                    class="q-tabs shadow-0 bordered"
                                    v-model="reportMode"
                                    mandatory
                                    dense
                                >
                                  <v-btn value="d" style="height: 40px !important;">Daily</v-btn>
                                  <v-btn value="w" style="height: 40px !important;">Weekly</v-btn>
                                  <v-btn value="m" style="height: 40px !important;">Monthly</v-btn>
                                  <v-btn value="y" style="height: 40px !important;"> Yearly</v-btn>
                                </v-btn-toggle>
                              </div>
                            </v-col>
                          </v-row>
                          <table class="table mt-4">
                            <thead>
                            <tr class="opacity-70 tr-neon tr-rounded">
                              <th class="">
                                {{ reportMode == "d" ? "Date" : "Date Range" }}
                              </th>
                              <th class="">Bookings</th>
                              <th class="">Sales</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in performanceReport" :key="item.date">
                              <td>
                                {{ reportMode == "w" ? item.date : item.date }}
                              </td>
                              <td>{{ item.bookings }}</td>
                              <td>
                                {{ item.sales ? item.sales : 0 | toCurrency }}
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col md="6">
                      <v-card class="fill-height bordered shadow-0">
                        <v-card-text class="">
                          <v-row dense class="border-bottom">
                            <v-col cols="12">
                              <div class="d-flex justify-space-between align-center">
                                <p class="font-bold text-lg black-text">Pricing Details</p>
                              </div>
                              <div></div>
                            </v-col>
                          </v-row>
                          <table class="table mt-4">
                            <thead>
                            <tr class="opacity-70 tr-neon tr-rounded">
                              <th class="">Timing</th>
                              <th class="">Weekdays</th>

                              <th class="">Name</th>
                              <th class="">Price</th>
                            </tr>
                            </thead>
                            <tbody
                                v-if="
                          facilityDetails && facilityDetails.facility_rentals
                        "
                            >
                            <tr
                                v-for="rental in facilityDetails.facility_rentals"
                                :key="rental.id"
                            >
                              <td>
                            <span v-if="rental">
                              {{ rental.start_time | timeFormat }}
                              -
                              {{ rental.end_time | timeFormat }}
                            </span>
                              </td>

                              <td>
                                <div
                                    v-if="rental"
                                    style="max-width: 100px"
                                    class="text-truncate"
                                >
                                  {{ getWeekdaysFromBitValue(rental.weekdays) }}
                                </div>
                              </td>
                              <td>
                                <div>
                                  <tr
                                      v-for="product in rental.facility_rental_product"
                                      :key="product.id"
                                  >
                                    <td>{{ product.name }}</td>
                                  </tr>
                                </div>
                              </td>

                              <td>
                                <div>
                                  <tr
                                      width="50%"
                                      v-for="product in rental.facility_rental_product"
                                      :key="product.id"
                                  >
                                    <td>{{ product.price }}</td>
                                  </tr>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col md="12" >
                      <v-card class="shadow-0 bordered">
                        <v-card-text>
                          <v-row dense
                                 :class="[facilityDetails.childFacility ? 'border-bottom' : '']"
                          >
                            <v-col cols="12">
                              <div class="d-flex justify-space-between align-center">
                                <h3  class="text-base font-semibold black-text ">Dependent Facilities</h3>
                                <v-switch
                                    v-if="checkWritePermission($modules.facility.management.slug) && !venueServiceConfiguration.is_golf_enabled"
                                    true-value="1"
                                    false-value="0"
                                    @change="childFacilityChange($event)"
                                    v-model="facilityDetails.childFacility"
                                    dense
                                    hide-details
                                ></v-switch>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row dense v-if="facilityDetails.childFacility == 1 ">
                            <v-col
                                md="6" lg="6" xl="4"
                                v-for="(facility, di) in facilityDetails.parent_facilities"
                                :key="di"
                            >
                              <dependency-widget
                                  :index="di"
                                  :image="facility.image"
                                  :facility="facility.name"
                                  :service="
                      facility.facility_services.length
                        ? facility.facility_services[0].venue_service.service
                            .name
                        : null
                    "
                                  :isParent="true"


                              ></dependency-widget>
                            </v-col>
                            <v-col
                                md="6" lg="6" xl="4"
                                v-for="(facility, di) in facilityDetails.child_facilities"
                                :key="`cf_${di}`"
                            >
                              <dependency-widget
                                  :key="`dw_${di}`"
                                  :index="di"
                                  :image="facility.image"
                                  :facility="facility.name"
                                  :service="
                      facility.facility_services.length
                        ? facility.facility_services[0].venue_service.service
                            .name
                        : null
                    "
                                  @remove="removeChildFacility"

                              ></dependency-widget>
                            </v-col>
                            <v-col md="6" lg="6" xl="4">
                              <v-card
                                  class="rounded-2 shadow-0 bordered mt-2" style="background-color: rgba(248, 250, 251, 1)"
                              >
                                <v-card-text>
                                  <v-row dense>
                                    <v-col md="12">
                                      <div class="d-flex gap-x-5 align-end">
                                        <div>
                                          <label for="">Service</label>
                                          <v-select
                                              v-on:change="getFacilities()"
                                              :items="venueServices"
                                              v-model="childFacilityForm.venue_service_id"
                                              item-value="venue_service_id"
                                              item-text="name"
                                              outlined
                                              :menu-props="{ bottom: true, offsetY: true }"
                                              background-color="#fff"
                                              class="q-autocomplete shadow-0"
                                              dense
                                              hide-details
                                          ></v-select>
                                        </div>
                                        <div>
                                          <label for="">Facility</label>
                                          <v-select
                                              :items="facilities"
                                              v-model="childFacilityForm.facility"
                                              item-value="id"
                                              item-text="name"
                                              outlined
                                              :menu-props="{ bottom: true, offsetY: true }"
                                              return-object
                                              background-color="#fff"
                                              class="q-autocomplete shadow-0"
                                              dense
                                              hide-details
                                          ></v-select>
                                        </div>

                                        <div>
                                          <v-btn color="teal-color" class="shadow-0"  dark @click="addChildFacility">
                                            Add
                                          </v-btn>
                                        </div>
                                      </div>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>

                    </v-col>

                    <v-col md="12">
                      <v-card class="shadow-0 bordered">
                        <v-card-title>Products</v-card-title>
                        <v-card-text>
                          <v-row>
                            <v-col
                                md="4" xl="3"
                                v-for="(product, index) in products"
                                :key="index"
                            >
                              <product-widget
                                  :key="index"
                                  :index="index"
                                  v-bind="product"
                                  @edit="editProduct"
                                  @delete="deleteProduct"
                              ></product-widget>
                            </v-col>
                          </v-row>
                          <v-pagination
                              class="mt-2 new-pagination"
                              v-model="page"
                              :length="totalPages"
                          ></v-pagination>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item value="maintenance">
                  <v-card class="shadow-0 bordered">

                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12">
                          <div class="d-flex justify-space-between align-center">
                            <SvgIcon class="text-2xl font-semibold black-text" text="Maintenance Logs" >
                            </SvgIcon>
                            <v-btn
                                v-if="checkWritePermission($modules.facility.management.slug)"
                                color="#062b48"
                                dark
                                @click="addMaintenance"
                            >
                              Add Maintenance
                              <v-icon right dark>mdi-cogs</v-icon>
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                     <v-row dense class="mt-2">
                       <v-col cols="12">
                         <table  class="table border-collapse">
                           <thead>
                           <tr class="opacity-70 tr-neon tr-rounded ">
                             <th class="">
                               <div  style="width: 150px">
                                 Start Date
                                 <date-menu
                                     v-model="searchParams.start_date"
                                     @change="getMaintenances"
                                     class-name="q-text-field shadow-0"
                                     :hide-details="true"
                                     :flat="true"
                                     :dense="true"
                                 >
                                 </date-menu>
                               </div>
                             </th>
                             <th class="">
                               <div  style="width: 150px">
                                 End Date
                                 <date-menu
                                     v-model="searchParams.end_date"
                                     @change="getMaintenances"
                                     class-name="q-text-field shadow-0"
                                     :hide-details="true"
                                     :flat="true"
                                     :dense="true"
                                 >
                                 </date-menu>
                               </div>
                             </th>
                             <th class="">Start Time</th>
                             <th class="">End Time</th>
                             <th class="">Status</th>
                             <th class="">Action</th>
                           </tr>
                           </thead>
                           <tbody>
                           <tr v-for="(log, index) in maintenance_log" :key="index">
                             <td>{{ log.start_date | dateformat }}</td>
                             <td>{{ log.end_date | dateformat }}</td>
                             <td>{{ log.start_time | timeFormat }}</td>
                             <td>{{ log.end_time | timeFormat }}</td>
                             <td>
                               {{ log.status_id == 1 ? "Active" : "Deactivated" }}
                             </td>
                             <td>
                               <v-tooltip bottom>
                                 <template v-slot:activator="{ on, attrs }">
                                   <v-icon
                                       v-bind="attrs"
                                       v-on="on"
                                       @click="confirmMaintenanceAction(index)"
                                       :color="
                                `${log.status_id == 1 ? 'error' : 'success'}`
                              "
                                   >mdi-{{
                                       log.status_id == 1 ? "delete" : "refresh"
                                     }}</v-icon
                                   >
                                 </template>
                                 <span>{{
                                     log.status_id == 1
                                         ? "Deactivate Maintenance"
                                         : "Activate Maintenance"
                                   }}</span>
                               </v-tooltip>
                             </td>
                           </tr>
                           </tbody>
                         </table>
                         <v-pagination
                             v-model="maintenancePage"
                             :length="maintenanceTotalPages"
                             class="new-pagination"
                         ></v-pagination>
                       </v-col>
                     </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item
                    value="colorPalette"

                >
                  <v-card class="fill-height">
                    <v-card-title
                    >Schedule Slots Color Configuration<v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                      <v-form ref="">
                        <v-card
                            class="shadow-0 bordered"
                        >
                         <v-card-text>
                           <v-row dense   v-for="(code, cIndex) in colorCodes"
                                  :key="`cc_${cIndex}`" >

                             <v-col cols="12">
                               <div class="d-flex gap-x-5">
                                 <div>
                                   <label for="">Type *</label>
                                   <v-select
                                       :items="colorTypes"
                                       v-model="code.type"
                                       item-text="text"
                                       item-value="value"
                                       :rules="[(v) => !!v || 'Type is required']"
                                       required
                                       outlined
                                       :menu-props="{ bottom: true, offsetY: true }"
                                       background-color="#fff"
                                       class="q-autocomplete shadow-0"
                                       dense
                                       hide-details
                                   ></v-select>
                                 </div>


                                 <div>
                                   <label for="">Code name *</label>
                                   <v-text-field
                                       outlined
                                       background-color="#fff"
                                       light
                                       v-model="code.name"
                                       :rules="[(v) => !!v || 'Code name is required']"
                                       class="q-text-field shadow-0"
                                       dense
                                       hide-details
                                   ></v-text-field>
                                 </div>
                                 <div>
                                   <label for="">Color code *</label>
                                   <v-text-field
                                       outlined
                                       v-model="code.color_code"
                                       background-color="#fff"
                                       light
                                       :rules="[(v) => !!v || 'Color code is required']"
                                       class="q-text-field shadow-0"
                                       dense
                                       hide-details
                                       style="min-width: 152px"
                                   >
                                     <template v-slot:append>
                                       <v-menu
                                           top
                                           nudge-bottom="105"
                                           nudge-left="16"
                                           :close-on-content-click="false"
                                       >
                                         <template v-slot:activator="{ on }">
                                           <div :style="swatchStyle(cIndex)" v-on="on" />
                                         </template>
                                         <v-card>
                                           <v-card-text class="pa-0">
                                             <v-color-picker
                                                 v-model="code.color_code"
                                                 flat
                                                 :swatches="swatches"
                                                 show-swatches
                                             />
                                           </v-card-text>
                                         </v-card>
                                       </v-menu>
                                     </template>
                                   </v-text-field>
                                 </div>



                                 <div v-if="code.type === 'date'">
                                   <label for="">Date*</label>
                                   <date-field
                                       v-model="code.date"
                                       label="Date*"
                                       :rules="[(v) => !!v || 'Date is required']"
                                       :dense="true"
                                       :hide-details="true"
                                       class-name="q-text-field shadow-0"
                                   >
                                   </date-field>
                                 </div>


                                 <div v-else>
                                   <label for="">Days Applicable *</label>
                                   <v-select
                                       :items="weekdays"
                                       item-text="name"
                                       item-value="bit_value"
                                       multiple
                                       outlined
                                       :menu-props="{ bottom: true, offsetY: true }"
                                       background-color="#fff"
                                       validate-on-blur
                                       class="q-autocomplete shadow-0"
                                       dense
                                       hide-details
                                       :rules="[
                            (v) =>
                              v.length > 0 || 'Applicable days is required',
                          ]"
                                       v-model="code.weekdays"
                                   >
                                     <template
                                         v-if="weekdays.length == code.weekdays.length"
                                         v-slot:selection="{ index }"
                                     >
                                       <span v-if="index == 0">All Days</span>
                                     </template>
                                     <template v-else v-slot:selection="{ item, index }">
                                       <span v-if="index == 0">{{ item.name }}</span>
                                       <span v-if="index == 1">, {{ item.name }}</span>
                                       <span v-if="index == 2">, ...</span>
                                     </template>
                                     <template v-slot:prepend-item>
                                       <v-list-item
                                           ripple
                                           @click="toggle('rentalweekdays', cIndex)"
                                       >
                                         <v-list-item-action>
                                           <v-icon
                                               :color="
                                    code.weekdays.length > 0
                                      ? 'indigo darken-4'
                                      : ''
                                  "
                                           >{{
                                               getIcon("rentalweekdays", cIndex)
                                             }}</v-icon
                                           >
                                         </v-list-item-action>
                                         <v-list-item-content>
                                           <v-list-item-title>All Days</v-list-item-title>
                                         </v-list-item-content>
                                       </v-list-item>
                                     </template>
                                   </v-select>
                                 </div>


                                 <div>
                                   <label for="">From *</label>
                                   <v-select
                                       :items="timings.slice(0, timings.length - 1)"
                                       :label="`From *`"
                                       item-text="text"
                                       item-value="value"
                                       :rules="[(v) => !!v || 'From time is required']"
                                       v-model="code.start_time"
                                       required
                                       outlined
                                       :menu-props="{ bottom: true, offsetY: true }"
                                       background-color="#fff"
                                       class="q-autocomplete shadow-0"
                                       dense
                                       hide-details
                                   ></v-select>
                                 </div>


                                 <div>
                                   <label for="">To *</label>
                                   <v-select
                                       :items="timings.slice(1)"
                                       item-text="text"
                                       item-value="value"
                                       v-model="code.end_time"
                                       :rules="[(v) => !!v || 'To time is required']"
                                       required
                                       outlined
                                       :menu-props="{ bottom: true, offsetY: true }"
                                       background-color="#fff"
                                       class="q-autocomplete shadow-0"
                                       dense
                                       hide-details
                                   ></v-select>
                                 </div>


                                 <div>
                                   <v-tooltip bottom>
                                     <template v-slot:activator="{ on, attrs }">
                                       <v-btn
                                           v-bind="attrs"
                                           v-on="on"
                                           class="del_btn mt-6"
                                           color="red"
                                           @click="deleteCode(cIndex)"
                                           x-small
                                           dark
                                           fab
                                       >
                                         <v-icon small>mdi-delete</v-icon>
                                       </v-btn>
                                     </template>
                                     Delete
                                   </v-tooltip>
                                 </div>
                               </div>
                             </v-col>

                           </v-row>


                           <v-row dense>
                             <v-col cols="12">
                               <p class="text-neon text-base text-underline pointer"  @click="addColorCode()">
                                 +Add New
                               </p>
                             </v-col>
                           </v-row>
                         </v-card-text>
                        </v-card>

                      </v-form>


                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          class="white--text blue-color mr-2 mb-2"
                          @click="saveColorCodes($event)"
                      >Update</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-tab-item>
                <v-tab-item
                    value="teeConfiguration"
                    v-if="checkReadPermission($modules.facility.control.slug)"
                >
                  <v-card class="shadow-0 bordered">
                    <v-card-title>Schedule<v-spacer></v-spacer> </v-card-title>
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12">
                          <v-form ref="cform">
                            <div class="titles">Configuration</div>
                            <v-card
                                class="mb-4 rounded-3 shadow-0 bordered"
                            >
                              <v-card-text>
                                <v-row   v-for="(control, cIndex) in teeControls" dense  :key="`cc_${cIndex}`">
                                  <v-col lg="2">
                                    <label>Type *</label>
                                    <v-select
                                        :items="controlTypes"
                                        v-model="control.type"
                                        item-text="text"
                                        item-value="value"
                                        :rules="[(v) => !!v || 'Type is required']"
                                        required
                                        outlined
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        background-color="#fff"
                                        class="q-autocomplete shadow-0"
                                        dense
                                        hide-details
                                    ></v-select>
                                  </v-col>
                                  <v-col lg="3">
                                    <label>Date *</label>
                                    <date-field
                                        v-model="control.date"
                                        :rules="[(v) => !!v || 'Date is required']"
                                        class-name="q-text-field shadow-0"
                                        :dense="true"
                                        :hide-details="true"
                                    >
                                    </date-field>
                                  </v-col>
                                  <v-col lg="3">
                                    <label>From *</label>

                                    <v-select
                                        :items="timings.slice(0, timings.length - 1)"
                                        item-text="text"
                                        item-value="value"
                                        :rules="[(v) => !!v || 'From time is required']"
                                        v-model="control.start_time"
                                        required
                                        outlined
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        background-color="#fff"
                                        class="q-autocomplete shadow-0"
                                        dense
                                        hide-details
                                    ></v-select>
                                  </v-col>
                                  <v-col lg="3">
                                    <label>To *</label>
                                    <v-select
                                        :items="timings.slice(1)"
                                        item-text="text"
                                        item-value="value"
                                        v-model="control.end_time"
                                        :rules="[(v) => !!v || 'To time is required']"
                                        required
                                        outlined
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        background-color="#fff"
                                        class="q-autocomplete shadow-0"
                                        dense
                                        hide-details
                                    ></v-select>
                                  </v-col>
                                  <v-col md="1" >
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            class="del_btn mt-6"
                                            color="red"
                                            @click="deleteControl(cIndex)"
                                            x-small
                                            dark
                                            fab
                                        >
                                          <v-icon small>mdi-delete</v-icon>
                                        </v-btn>
                                      </template>
                                      Delete
                                    </v-tooltip>
                                  </v-col>


                                </v-row>

                                <v-row dense>
                                  <v-col cols="12">
                                    <p class="text-base text-underline text-neon pointer"  @click="addControl()">
                                      + Add new code

                                    </p>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>

                          </v-form>
                        </v-col>
                      </v-row>

                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          class="white--text blue-color mr-2 mb-2"
                          @click="saveControls($event)"
                      >Update</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>
    </v-row>

    <facility-maintenance
      :facilityId="maintenanceId"
      :venueServiceId="venueServiceId"
      @save="getMaintenances"
      @close="closeMaintenance"
      :timeIncrement="time_increment"
    ></facility-maintenance>
    <product-creation
      v-if="productDialog"
      :is-enable-add-product="productDialog"
      v-bind="productData"
      @save="(productDialog = false), getProducts()"
      @close="(productDialog = false), getProducts()"
      @deleteProduct="deleteProduct"
    />
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="(confirmModel.id = null), disableDependency()"
    ></confirm-model>
  </v-container>
</template>

<script>
import ProductWidget from "@/components/Product/ProductWidget";
import DependencyWidget from "@/components/Facility/DependencyWidget";
import ProductCreation from "@/components/Facility/ProductCreation";
import FacilityMaintenance from "@/components/Facility/FacilityMaintenance";
import constants from "../../utils/constants";
import moment from "moment";
import BackButton from "@/components/Common/BackButton.vue";
import EditBtnIcon from "@/assets/images/misc/pencil-icon.svg";
import PackagesIcon from "@/assets/images/trainers/packages.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
export default {
  components: {
    SvgIcon, PackagesIcon,
    EditBtnIcon,
    BackButton,
    ProductWidget,
    ProductCreation,
    DependencyWidget,
    FacilityMaintenance,
  },
  data() {
    return {
      searchParams: { start_date: null, end_date: null },
      tab: null,
      page: 1,
      totalPages: 1,
      maintenancePage: 1,
      maintenanceTotalPages: 1,
      facilityDetails: { childFacility: "0" },
      maintenanceId: null,
      maintenance_log: [],
      reportMode: "d",
      performanceReport: [],
      menu: false,
      valid: false,
      date: null,
      timeData: constants.TIMINGS,
      products: [],
      productData: {},
      productDialog: false,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      teeControls: [
        { type: "oo", date: null, start_time: null, end_time: null },
      ],
      colorCodes: [
        {
          name: null,
          type: "date",
          color_code: "#00AAAAFF",
          weekdays: [],
          start_time: null,
          end_time: null,
        },
      ],
      swatches: [
        ["#FF0000", "#AA0000", "#550000"],
        ["#FFFF00", "#AAAA00", "#555500"],
        ["#00FF00", "#00AA00", "#005500"],
        ["#00FFFF", "#00AAAA", "#005555"],
        ["#0000FF", "#0000AA", "#000055"],
      ],
      deleted_codes: [],
      deleted_controls: [],
      childFacilityForm: {},
      facilities: [],
      venue_service_id: null,
      venueServiceConfiguration: [],
      time_increment: 60,
      venueServiceId: null,
      timings: [],
      timeIncrement: 10,
      colorTypes: [
        {
          text: "Date",
          value: "date",
        },
        {
          text: "Rule",
          value: "rule",
        },
      ],
      controlTypes: [
        {
          text: "Operator Only",
          value: "oo",
        },
        {
          text: "Operator and Members",
          value: "om",
        },
      ],
    };
  },
  mounted() {
    if (this.$route.params.data) {
      this.facilityDetails.id = parseInt(atob(this.$route.params.data));
    }
    if (this.$store.getters.getWeekdays.status == false) {
      this.$store.dispatch("loadWeekdays");
    }

    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    this.getFacilitiesDetails();
    this.getProducts();
    this.getColorCodes();
    this.getControls();
  },
  computed: {
    venueServices() {
      return this.$store.getters.getSportsService;
    },
    weekdays() {
      return this.$store.getters.getWeekdays.data;
    },
  },
  watch: {
    maintenancePage() {
      this.getMaintenances();
    },
    reportMode: {
      handler() {
        this.getFacilityUsage();
      },
      immediate: true,
    },
  },
  methods: {
    deleteGround(id) {
      this.confirmModel = {
        id: id,
        title: `Do you want to delete this facility?`,
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "delete_facility",
      };
    },

    toggle(type, field) {
      this.$nextTick(() => {
        if (type == "rentalweekdays") {
          if (this.colorCodes[field].weekdays.length == this.weekdays.length) {
            this.colorCodes[field].weekdays = [];
          } else {
            this.colorCodes[field].weekdays = this.weekdays.map(
              (item) => item.bit_value
            );
          }
        }
      });
      this.$forceUpdate();
    },

    getIcon(type, field) {
      let icon = "mdi-checkbox-blank-outline";

      if (type == "rentalweekdays") {
        if (this.colorCodes[field].weekdays.length == this.weekdays.length) {
          icon = "mdi-close-box";
        }
        if (
          this.colorCodes[field].weekdays.length > 0 &&
          this.colorCodes[field].weekdays.length != this.weekdays.length
        )
          icon = "mdi-minus-box";
      }
      return icon;
    },

    swatchStyle(cIndex) {
      const { color_code, menu } = this.colorCodes[cIndex];
      return {
        backgroundColor: color_code,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
    getColorCodes() {
      this.$http
        .get(
          `venues/facilities/color-palette?facility_id=${this.facilityDetails.id}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            if (data.length) {
              this.colorCodes = data;
            } else {
              this.colorCodes = [
                {
                  name: null,
                  type: "date",
                  color_code: "#00AAAAFF",
                  weekdays: [],
                  start_time: null,
                  end_time: null,
                },
              ];
            }
            this.deleted_codes = [];
            this.$forceUpdate();
            if (this.$refs.cform) {
              this.$refs.cform.resetValidation();
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getControls() {
      this.$http
        .get(
          `venues/facilities/schedule-controls?facility_id=${this.facilityDetails.id}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            if (data.length) {
              this.teeControls = data;
            } else {
              this.teeControls = [
                { type: "oo", date: null, start_time: null, end_time: null },
              ];
            }
            this.deleted_controls = [];
            this.$forceUpdate();
            if (this.$refs.cform) {
              this.$refs.cform.resetValidation();
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    generateTiming() {
      let currentTime = moment("00:00:00", "HH:mm:ss");
      this.timings = [];
      this.timings.push({
        value: currentTime.format("HH:mm:ss"),
        text: currentTime.format("hh:mm a"),
      });
      let hour = 0;
      while (hour < 24) {
        currentTime = currentTime.add(this.timeIncrement, "minutes");
        let data = {
          value: currentTime.format("HH:mm:ss"),
          text: currentTime.format("hh:mm a"),
        };
        if (data.value == "00:00:00") {
          data.value = "23:59:59";
          this.timings.push(data);
          hour = 24;
        } else {
          this.timings.push(data);
          hour = currentTime.get("hours");
        }
      }
    },
    saveControls() {
      let data = {
        facility_id: this.facilityDetails.id,
        controls: this.teeControls,
      };
      if (this.deleted_controls.length > 0) {
        data.deleted_controls = this.deleted_controls;
      }
      this.showLoader();
      this.$http
        .post(`venues/facilities/schedule-controls`, data)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Controls saved");
            this.getControls();
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    saveColorCodes() {
      // if (!this.$refs.cform.validate()) {
      //   this.showError("Please fill all required fields");
      //   return;
      // }
      let data = {
        facility_id: this.facilityDetails.id,
        color_codes: this.colorCodes,
      };
      if (this.deleted_codes.length) {
        data.deleted_codes = this.deleted_codes;
      }
      this.showLoader();
      this.$http
        .post(`venues/facilities/color-palette`, data)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Color codes saved");
            this.getColorCodes();
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    deleteAllCodes() {
      let data = {
        facility_id: this.facilityDetails.id,
      };

      this.showLoader();
      this.$http
        .post(`venues/facilities/color-palette/remove-all`, data)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("All Color codes Removed");
            this.addColorCode();
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    deleteAllControls() {
      let data = {
        facility_id: this.facilityDetails.id,
      };

      this.showLoader();
      this.$http
        .post(`venues/facilities/schedule-controls/remove-all`, data)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("All Controls Removed");
            this.addControl();
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    deleteCode(cIndex) {
      let pkg = this.colorCodes[cIndex];

      if (pkg.name == null && pkg.color_code == null && pkg.id == null) {
        this.colorCodes.splice(cIndex, 1);
        this.$forceUpdate();
      } else {
        this.confirmModel = {
          id: cIndex,
          title: "Do you want to delete this color code?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action?",
          type: "color_code",
        };
      }
    },
    deleteControl(cIndex) {
      let pkg = this.teeControls[cIndex];

      if (pkg.name == null && pkg.color_code == null && pkg.id == null) {
        this.teeControls.splice(cIndex, 1);
        this.$forceUpdate();
      } else {
        this.confirmModel = {
          id: cIndex,
          title: "Do you want to delete this control?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action?",
          type: "tee_control",
        };
      }
    },
    addColorCode() {
      this.colorCodes.push({
        name: null,
        type: "date",
        color_code: "#00AAAAFF",
        weekdays: [],
        start_time: null,
        end_time: null,
      });
      if (this.$refs.cform) {
        this.$refs.cform.resetValidation();
      }
    },
    addControl() {
      this.teeControls.push({
        type: "oo",
        date: null,
        start_time: null,
        end_time: null,
      });
      if (this.$refs.cform) {
        this.$refs.cform.resetValidation();
      }
    },
    addMaintainGround() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.maintainData.facility_id = this.facilityDetails.id;
      this.maintainData.venue_service_id = this.facilityDetails.facility_services[0].venue_service_id;
      this.$http
        .post("venues/facilities/maintenances", this.maintainData)
        .then((response) => {
          if (response.status == 200) {
            this.getMaintenances();
            this.maintainGroundClose();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getFacilityUsage() {
      if (!this.facilityDetails.id) return;
      let rows = 2;
      let newParameters = {
        facility_id: this.facilityDetails.id,
        mode: this.reportMode,
      };
      switch (newParameters.mode) {
        case "d": {
          newParameters.start_date = moment()
            .subtract(rows, "days")
            .format("YYYY-MM-DD");
          newParameters.end_date = moment().format("YYYY-MM-DD");
          break;
        }
        case "w": {
          newParameters.start_date = moment()
            .subtract(rows, "weeks")
            .format("YYYY-MM-DD");
          newParameters.end_date = moment().format("YYYY-MM-DD");
          break;
        }
        case "m": {
          newParameters.start_date = moment()
            .subtract(rows, "months")
            .format("YYYY-MM-DD");
          newParameters.end_date = moment().format("YYYY-MM-DD");
          break;
        }
        case "y": {
          newParameters.start_date = moment()
            .subtract(rows, "years")
            .format("YYYY-MM-DD");
          newParameters.end_date = moment().format("YYYY-MM-DD");
          break;
        }
        default: {
          newParameters.mode = "d";
          newParameters.start_date = moment()
            .subtract(rows, "days")
            .format("YYYY-MM-DD");
          newParameters.end_date = moment().format("YYYY-MM-DD");
        }
      }

      this.$http
        .get("venues/facilities/salesreport", {
          params: newParameters,
        })
        .then((response) => {
          if (response.status == 200) {
            this.performanceReport = response.data.data.reverse();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getMaintenances() {
      this.showLoader("Loading....");
      let start_date = "";
      if (this.searchParams.start_date != null) {
        start_date = "&start_date=" + this.searchParams.start_date;
      }
      let end_date = "";
      if (this.searchParams.end_date != null) {
        end_date = "&end_date=" + this.searchParams.end_date;
      }
      this.$http
        .get(
          `venues/facilities/maintenances?page=${this.maintenancePage}&facility_id=${this.facilityDetails.id}` +
            start_date +
            end_date
        )
        .then((response) => {
          this.hideLoader();
          if (response.status == 200 && response.data.status == true) {
            this.maintenance_log = response.data.data;
            this.maintenanceTotalPages = response.data.total_pages;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    getProducts() {
      this.$http
        .get(`venues/facilities/products/${this.facilityDetails.id}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data;
            this.products = data.data;
            this.totalPages = data.total_pages;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    getFacilitiesDetails() {
      this.$http
        .get(`venues/facilities/${this.facilityDetails.id}`)
        .then((response) => {
          if (
            response.status == 200 &&
            response.data.status == true &&
            response.data.data
          ) {
            this.facilityDetails = response.data.data;
            this.venueServiceId = this.facilityDetails.facility_services[0].venue_service_id;

            if (
              !this.$store.getters.getConfigurationStatus(this.venueServiceId)
            ) {
              this.$store
                .dispatch(
                  "loadConfigurationsByVenueServiceId",
                  this.venueServiceId
                )
                .then((response) => {
                  if (response.status == 200) {
                    this.venueServiceConfiguration = this.$store.getters.getConfigurationByVenueServiceId(
                      this.venueServiceId
                    );
                  }
                });
            } else {
              this.venueServiceConfiguration = this.$store.getters.getConfigurationByVenueServiceId(
                this.venueServiceId
              );
            }

            if (
              this.venueServiceConfiguration.time_increment != null &&
              this.venueServiceConfiguration.time_increment != undefined
            ) {
              this.time_increment = this.venueServiceConfiguration.time_increment;
            } else {
              this.time_increment = 60;
            }

            if (
              this.facilityDetails.child_facilities.length > 0 ||
              this.facilityDetails.parent_facilities.length
            ) {
              this.facilityDetails.childFacility = "1";
            }
            this.getMaintenances();
            this.getFacilityUsage();
            this.generateTiming();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    closeMaintenance() {
      this.getMaintenances();
      this.maintenanceId = null;
    },
    addMaintenance() {
      this.maintenanceId = this.facilityDetails.id;
    },

    getGameFormationName() {
      if (this.facilityDetails.game_formations && this.facilityDetails.game_formations.length)
        return this.facilityDetails.game_formations
          .map((item) => item.name)
          .join(",");
      return "NA";
    },

    editProduct(data) {
      this.productData = this.products[data.index];
      this.productData.image_path = this.productData.image;
      this.productDialog = true;
    },

    deleteProduct(productId , categoryId) {
      this.confirmModel = {
        id: productId,
        title: "Do you want to delete this product?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "delete_product",
        data: {productCategoryId: categoryId},
      };
    },

    confirmActions(data) {
      if (data.type == "delete_product") {
        this.$http
          .delete(`venues/products/${data.id}?category_id=${data.data.productCategoryId}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.productDialog = false;
              this.getProducts();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      } else if (data.type == "delete_dependency") {
        this.facilityDetails.deleted_child_facilities = [];
        this.facilityDetails.deleted_child_facilities = this.facilityDetails.child_facilities.map(
          (x) => x.id
        );
        this.$nextTick(() => {
          this.facilityDetails.childFacility = "0";
          this.facilityDetails.child_facilities = [];
          this.$forceUpdate();
          this.updateFacilities();
        });
      } else if (data.type == "maintenance") {
        this.changeMaintenanceStatus(data.data.id);
      } else if (data.type == "color_code") {
        let index = data.id;
        if (this.colorCodes[index].id != null) {
          this.deleted_codes.push(this.colorCodes[index].id);
        }
        this.colorCodes.splice(index, 1);
        if (this.colorCodes.length == 0) {
          this.deleteAllCodes();
        }
        this.$forceUpdate();
      } else if (data.type == "tee_control") {
        let index = data.id;
        if (this.teeControls[index].id != null) {
          this.deleted_controls.push(this.teeControls[index].id);
        }
        this.teeControls.splice(index, 1);
        if (this.teeControls.length == 0) {
          this.deleteAllControls();
        }
        this.$forceUpdate();
      }else if (data.type == "delete_facility") {
        this.showLoader("Deleting.....");
        this.$http
            .delete(`venues/facilities/${data.id}`)
            .then(() => {
              this.hideLoader()
              this.gotoFacilities();
            })
            .catch((error) => {
              this.errorChecker(error);
            });
        this.$forceUpdate();
      }
      this.confirmModel.id = null;
    },
    changeMaintenanceStatus(id) {
      this.showLoader("Deleting.....");
      this.$http
        .put("venues/facilities/maintenances/" + id + "/status")
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.getMaintenances();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    gotoFacilities() {
      this.$router.push({
        name: "FacilityPerService",
        params: {
          data: btoa(
            JSON.stringify({
              venue_service_id: this.facilityDetails.facility_services[0]
                .venue_service_id,
            })
          ),
        },
      });
    },

    addChildFacility() {
      if (this.childFacilityForm.facility != undefined) {
        this.facilityDetails.child_facilities.push(
          this.childFacilityForm.facility
        );
        this.childFacilityForm = {};
        this.updateFacilities();
      } else {
        this.showError("Please select facility");
      }
    },

    updateFacilities() {
      var formData = {};
      if (this.facilityDetails.child_facilities.length > 0) {
        formData.child_facilities = [];
        this.facilityDetails.child_facilities.forEach((element) => {
          formData.child_facilities.push(element.id);
        });
      }

      if (
        this.facilityDetails.deleted_child_facilities != null &&
        this.facilityDetails.deleted_child_facilities.length
      ) {
        formData.deleted_child_facilities = [];
        this.facilityDetails.deleted_child_facilities.forEach((id) => {
          formData.deleted_child_facilities.push(id);
        });
      }

      this.$http({
        method: "post",
        data: formData,
        url: `venues/facilities/children/${this.facilityDetails.id}`,
      })
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    getFacilities() {
      this.showLoader("Loading Facilities");
      this.facilities = [];
      this.$http
        .get(
          `venues/facilities/children?venue_service_id=${
            this.childFacilityForm.venue_service_id
          }${
            this.facilityDetails.id != null
              ? "&current_facility_id=" + this.facilityDetails.id
              : ""
          }`
        )
        .then((response) => {
          this.hideLoader();
          if (response.status == 200 && response.data.status == true) {
            this.facilities = response.data.data;
            if (this.facilities.length == 0) {
              this.showError("No facilities available of selected service");
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    removeChildFacility(index) {
      let id = this.facilityDetails.child_facilities[index].id;
      if (!this.facilityDetails.deleted_child_facilities) {
        this.facilityDetails.deleted_child_facilities = [];
      }
      this.facilityDetails.deleted_child_facilities.push(id);
      this.facilityDetails.child_facilities.splice(index, 1);
      this.updateFacilities();
    },

    childFacilityChange(val) {
      if (val == 1 && this.facilityDetails.child_facilities.length == 0) {
        this.facilityDetails.child_facilities = [];
        this.$forceUpdate();
      }
      if (val == 0 && this.facilityDetails.child_facilities.length != 0) {
        this.confirmModel = {
          id: 0,
          title: "Do you want to delete this Dependency?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "delete_dependency",
        };
        this.disableDependency();
      }
      this.$forceUpdate();
    },

    confirmMaintenanceAction(index) {
      let data = this.maintenance_log[index];
      this.confirmModel = {
        id: index,
        title: `Do you want to ${
          data.status_id == 1 ? "deactivate" : "activate"
        } this maintenance?`,
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "maintenance",
        data: data,
      };
    },

    disableDependency() {
      if (this.confirmModel.type == "delete_dependency") {
        this.$nextTick(() => {
          this.facilityDetails.childFacility = "1";

          this.$forceUpdate();
        });
      }
    },

    getWeekdaysFromBitValue(bitArray) {
      if (bitArray && bitArray.length) {
        return this.weekdays
          .filter((item) => bitArray.includes(item.bit_value))
          .map((item) => item.name)
          .join(",");
      }
      return "NA";
    },

    getGameFormation() {
      if (this.game_formations.length > 0) {
        return this.game_formations.map((item) => item.name).join(", ");
      }
      return "--";
    },


    edit() {
      this.$router.push({
        name: "FacilityForm",
        params: {
          data: btoa(
              JSON.stringify({
                venue_service_id: this.venueServiceId,
                facility_id: this.facilityDetails.id,
              })
          ),
        },
      });
    },
  },
};
</script>

<style scoped>
.eventbox {
  background-color: rgba(8, 8, 8, 0.397);
}
.ticketdetail {
  background-color: rgb(218, 218, 218);
  padding: 5px;
}
.tableHeader {
  background-color: #062b48 !important;
  color: #fff !important;
}
.section {
  margin: 20px 0 50px 0;
  padding: 20px 0 20px;
}
.chart_ic {
  background-image: url("../../assets/images/nav_icon/graph.png");
  padding: 10px;
  background-size: contain;
}
.tab_active {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
</style>

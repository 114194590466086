<template>

  <v-card
      class="rounded-2 shadow-0 bordered mt-2" style="background-color: rgba(248, 250, 251, 1)"
  >
    <v-tooltip bottom >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-bind="attrs"
            v-on="on"
            v-if="
            checkDeletePermission($modules.facility.management.slug) &&
            !isParent
          "
            @click="removeChildFacility"
            fab
            x-small
            absolute
            top
            right
            icon
            dark
        >
         <DeleteIcon/>
        </v-btn>
      </template>
      Delete
    </v-tooltip>
    <v-card-text>
      <v-row>
        <v-col md="6">
          <label for="">Service </label>
          <v-select
              :items="[service]"
              v-model="service"
              outlined
              :menu-props="{ bottom: true, offsetY: true }"
              background-color="#fff"
              class="q-autocomplete shadow-0"
              dense
              hide-details
              disabled

          ></v-select>
        </v-col>
        <v-col md="6">
          <label for="">Facility </label>
          <v-select
              :items="[facility]"
              v-model="facility"
              outlined
              :menu-props="{ bottom: true, offsetY: true }"
              return-object
              background-color="#fff"
              class="q-autocomplete shadow-0"
              dense
              hide-details
              disabled
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import DeleteIcon from '@/assets/images/misc/delete-bg-icon.svg';

export default {
  props: {
    service: { type: String, default: "--" },
    facility: { type: String, default: "--" },
    image: { type: String, default: "" },
    index: { type: Number, default: null },
    isParent: { type: Boolean, default: false },
  },

  components:{
    DeleteIcon
  },

  methods: {
    removeChildFacility() {
      this.$emit("remove", this.index);
    },

  },


};
</script>

<style>
</style>